
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";

export default defineComponent({
  name: "industry sector",
  components: {
    hideModal,
  },

  data() {
    return {
      formData: {
        name: "",
        level: "",
        description: "",
       
      } as any,
      loading: false,
      componentKey: 0 as any,
    };
  },
  async created() {
    this.emitter.on("edit-modal-data", async (data) => {
      this.formData = data;
    });
    this.emitter.on("add-modal-data", async (data) => {
      this.formData.id = "" as any;
      this.formData.name = "" as any;
      this.formData.level = "" as any;
      this.formData.description = "" as any;
     
    });
  },
  methods: {
    async formSubmit() {
      this.loading = true;
      await ApiService.post(
        "configurations/industry_sector/save",
        this.formData
      )
        .then((response) => {
          this.loading = false;
          if (response.data.status == "success") {
            this.emitter.emit("industry-added", true);
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => { });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
